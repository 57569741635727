import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
  selector: 'gf-comment-input',
  templateUrl: './comment-input.component.html',
  styleUrls: ['./comment-input.component.scss']
})
export class CommentInputComponent {
  @Input() comment = '';
  @Input() disabled = false;
  @Output() commented: EventEmitter<string> = new EventEmitter<string>();

  constructor(public translations: Translations) {
  }

  submit(event?: KeyboardEvent) {
    if (event) {
      // Don't let the "enter" create a newline
      event.preventDefault();
    }
    if (this.comment) {
      this.commented.emit(this.comment.trim());
      this.comment = '';
    }
  }

  updateComment(newComment: string): void {
    this.comment = newComment;
  }
}
