<mat-form-field appearance="fill" class="t-14-400-s width-100 no-borders">
  <textarea id="comment"
    name="comment"
    matInput
    rows="4"
    maxlength="1024"
    [readonly]="disabled"
    [ngModel]="comment"
    (ngModelchange)="updateComment($event)"
    [placeholder]="translations.common.write_a_comment | translate"
    (keydown.shift.enter)="submit($event)">
  </textarea>
  <!--
  <button mat-icon-button
          class="cursor-pointer"
          matSuffix
          [disabled]="!comment?.length"
          (click)="submit()">
    <mat-icon fontIcon="location_searching"></mat-icon>
  </button>
  -->
</mat-form-field>
<div class="flex-container align-right align-middle">
  <!-- GF-2834 Suggested to hide notification count for now. we can use it in the future.-->
  <!-- <div class="t-13-400-p">
    <span class="link-color">{{translations.common.x_people | translate :{x: 0} }}</span><span> {{translations.approval.will_be_notified | translate}}</span>
  </div> -->
  <button id="sendCommentBtn"
    mat-raised-button
    class="large"
    color="secondary"
    [disabled]="!comment?.trim()"
    (click)="submit()">
    {{translations.common.send | translate}}
  </button>
</div>
