<div class="flex-container align-top p-l-3 p-y-3 p-r-1">
  <gf-user-avatar *ngIf="comment.createdBy else isCollaborator" [userId]="comment.createdBy" [size]="imagePreviewType.SMALL" [profilePicSize]="'small'"></gf-user-avatar>
  <ng-template #isCollaborator>
    <gf-collaborator-avatar [collaborator]="comment.collaboratorId | collaborator | async" [size]="'medium'"></gf-collaborator-avatar>
  </ng-template>
  <div class="flex-container flex-dir-column flex-child-auto comment-container">
    <div class="approval-comment-header flex-container align-center-middle p-a-1">
      <div class="flex-container flex-dir-column flex-child-auto label-container">
        <label *ngIf="comment.createdBy else showCollab" class="t-14-600-p text-truncate"
          [title]="comment.createdBy | user | async | userDisplayName : true"
          [innerHTML]="comment.createdBy | user | async | userDisplayName : true"></label>
        <!--TODO do this in a decent way-->
        <ng-template #showCollab>
          <label class="t-14-600-p text-truncate">{{(comment.collaboratorId | collaborator | async)?.email}}</label>
        </ng-template>
        <label class="t-14-400-h">{{comment.createdDate | fromNowDate}}</label>
      </div>
      <ng-container *ngIf="comment | canDeleteComment: replies | async">
        <mat-icon class="cursor-pointer" [matMenuTriggerFor]="addActions" fontIcon="more_vert"></mat-icon>
        <mat-menu #addActions="matMenu">
          <button class="danger-color" mat-menu-item (click)="deleteComment.emit(comment.commentId)">{{translations.common.delete | translate}}</button>
        </mat-menu>
      </ng-container>
    </div>
    <span class="t-14-400-p m-y-1 m-r-3 text-wrap">
      {{comment.comment}}
    </span>
    <div *ngIf="!!comment.actionCommentStatus" class="action-comment-wrap grid-y m-y-2 t-12-400-h align-center">
      <div class="action-status">
        <span class="text-capitalize">{{comment.actionCommentStatus}}</span> by
        <span *ngIf="!!comment?.createdBy"
          [title]="comment.createdBy | user | async | userDisplayName : true"
          [innerHTML]="comment.createdBy | user | async | userDisplayName : true"></span>
        <span *ngIf="!!comment?.collaboratorId">{{(comment.collaboratorId | collaborator | async)?.email}}</span>
      </div>
      <span class="t-12-400-h">{{comment.createdDate | date : 'MMMM dd, yyyy'}}</span>
    </div>
    <div class="replies-wrapper">
      <gf-approval-comment-reply *ngFor="let reply of replies" [reply]="reply" (deleteComment)="deleteComment.emit($event)">
      </gf-approval-comment-reply>
    </div>
    <div *ngIf="canReply" class="flex-child-shrink m-r-2" [class.reply-btn-disabled]="disabled">
      <button mat-flat-button
        disableRipple
        class="p-x-0"
        [disabled]="disabled"
        (click)="showReply = !showReply">
        <span class="mr-4" [class.t-14-400-p]="showReply"
              [class.t-14-400-h]="!showReply">
              {{translations.common.reply | translate}}
        </span>
        <mat-icon class="secondary" fontIcon="keyboard_arrow_down"></mat-icon>
      </button>
      <gf-comment-input *ngIf="showReply" (commented)="commented.emit($event)"></gf-comment-input>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
