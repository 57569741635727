import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { ColorPaletteQuery } from '@graphics-flow/util';

@Component({
  selector: 'gf-color-profile-disclaimer-dialog',
  templateUrl: './color-profile-disclaimer-dialog.component.html',
  styleUrls: ['./color-profile-disclaimer-dialog.component.scss'],
})
export class ColorProfileDisclaimerDialogComponent {
  isCMYK: boolean;
  dontShowMeAgain: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: boolean,
    private readonly dialogRef: MatDialogRef<ColorProfileDisclaimerDialogComponent>,
    public translations: Translations,
    public readonly colorPaletteQuery: ColorPaletteQuery
  ) {
    this.isCMYK = data;
  }

  switchTheColorProfile(): void {
    this.dialogRef.close({
      canSwitch: true,
      dontShowDisclaimer: this.dontShowMeAgain
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  updateDontShowMeAgainStatus(dontShowMeAgain: boolean): void {
    this.dontShowMeAgain = dontShowMeAgain;
  }
}
