import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { addEntities, deleteEntities, setActiveId, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { isNull as _isNull, isUndefined as _isUndefined } from 'lodash-es';
import { interval, Observable } from 'rxjs';
import { filter, take, tap, throttle } from 'rxjs/operators';

import { FolderHttpService } from '@graphics-flow/api';
import { ArtFolderPackage, FilesRoutes, Folder, ID, MyArtActiveQueryParams, WebJob } from '@graphics-flow/types';

import { BillingPlansService } from '../billing-plans/billing-plans.service';
import { TagService } from '../tag/tag.service';
import { FolderQuery } from './folder.query';
import { FolderStore } from './folder.state';
import { ArtService } from '../art/art.service';

@Injectable({
  providedIn: 'root'
})
export class FolderService {
  isExistingRootFoldersLoaded = false;

  constructor(
    private readonly artService: ArtService,
    private readonly billingPlansService: BillingPlansService,
    private readonly folderHttpService: FolderHttpService,
    private readonly folderQuery: FolderQuery,
    private readonly folderStore: FolderStore,
    private readonly tagService: TagService,
    private readonly location: Location
  ) {
  }

  addFolderEntities(folders: Folder | Folder[]): void {
    this.folderStore.update(addEntities(folders));
  }

  setActiveFolderId(folderId: ID): void {
    this.folderStore.update(setActiveId(folderId));
  }

  updateFolderEntities(folderIds: ID | ID[], partialFolder: Partial<Folder>): void {
    this.folderStore.update(updateEntities(folderIds, partialFolder));
  }

  upsertFolderEntities(folders: Folder | Folder[]): void {
    this.folderStore.update(upsertEntities(folders));
  }

  deleteFolderEntities(folderIds: ID | ID[]): void {
    this.folderStore.update(deleteEntities(folderIds));
  }

  createFolder(folder: Folder): Observable<Folder> {
    return this.folderHttpService.create(folder).pipe(
      tap((createdFolder: Folder) => {
        this.addFolderEntities(createdFolder);
      })
    );
  }

  updateFolder(folder: Folder): Observable<Folder> {
    return this.folderHttpService.update(folder).pipe(
      tap((updatedFolder: Folder) => {
        this.updateFolderEntities(folder.folderId, updatedFolder);
      })
    );
  }

  moveFolder(folderId: ID, destinationFolderId: ID): Observable<any> {
    const folder: Folder = this.folderQuery.getFolderEntity(folderId);
    // Verify that the move is legal
    if (folderId === destinationFolderId) {
      throw Error(`You can't move a folder into itself!`);
    } else if (folder.parentId === destinationFolderId) {
      // This folder is already in the destination folder!
    } else if (destinationFolderId && this.isAncestorOf(folderId, destinationFolderId)) {
      // You cannot move a folder into itself
      throw Error(`You can't move a folder into itself!`);
    }

    return this.folderHttpService.moveToFolder([], [folderId], destinationFolderId).pipe(
      tap(() => {
        this.updateFolderEntities(folderId, { parentId: destinationFolderId });
        this.billingPlansService.updateResourceUsageInBackground();
      })
    );
  }

  moveArtsAndFolders(artIds: ID[] = [], folderIds: ID[] = [], parentFolderId?: ID): Observable<ArtFolderPackage> {
    return this.folderHttpService.moveToFolder(artIds, folderIds, parentFolderId).pipe(
      tap((response: ArtFolderPackage) => {
        if (response?.art?.length) {
          if (_isNull(parentFolderId)) {
            // used only while moving an art from folder to root section.
            const updatedArtIds: ID[] = response.art.map((art) => art.artId);
            this.artService.updateArtEntities(updatedArtIds, { folderId: parentFolderId });
          }
          this.artService.addArtToStore(response.art);
        }

        if (folderIds?.length) {
          this.updateFolderEntities(folderIds, { parentId: parentFolderId });
        }
        this.billingPlansService.updateResourceUsageInBackground();
      })
    );
  }

  loadFolders(params?: MyArtActiveQueryParams): Observable<Folder[]> {
    return this.folderHttpService.getFolders(params).pipe(
      throttle(() => interval(1000)), // Call at most once per second?
      tap((folders: Folder[]) => {
        this.upsertFolderEntities(folders);
      })
    );
  }

  getFolders(): Folder[] {
    return this.folderQuery.getAllFolderEntities();
  }

  deleteFolder(folder: Folder): Observable<ArtFolderPackage> {
    return this.folderHttpService.delete(folder.folderId).pipe(
      filter((success) => !!success), // If the API call failed, bail
      take(1),
      tap(() => {
        // If the deleted folder was the active one, pick a new active folder (either the parent of the deleted folder
        // or the first root folder)
        if (this.folderQuery.getActiveFolderEntityId() === folder.folderId) {
          this.selectFolder(folder?.parentId);
        }
        this.deleteFolderEntities(folder?.folderId);
        this.billingPlansService.updateResourceUsageInBackground();

        this.tagService.getAllTags(true).subscribe();
      })
    );
  }

  selectFolder(folderId: ID): Observable<Folder> {
    return this.folderQuery.selectFolderEntity(folderId).pipe(
      take(1),
      tap((folder: Folder) => {
        this.setActiveFolderId(folder?.folderId || null);
      })
    );
  }

  getApprovalsByFolderId(folderId: ID) {
    return this.folderHttpService.getApprovalsByFolderId(folderId);
  }

  downloadArtsAndFolder(artIds: ID[], folderIds: ID[], name: string) {
    this.folderHttpService.downloadArtsAndFolders(artIds, folderIds, name);
  }

  private isAncestorOf(aId: ID, bId: ID): boolean {
    if (aId === bId) return true;
    const b = this.folderQuery.getFolderEntity(bId);
    return !!b.parentId && (b.parentId === aId || this.isAncestorOf(aId, b.parentId));
  }

  updateFolderInProgress(folderIds: ID[], status: boolean): void {
    this.updateFolderEntities(folderIds, { inProgress: status });
  }

  getFolderBreadCrumbs(folderId: ID): Observable<Folder[]> {
    return this.folderHttpService.getBreadCrumbs(folderId).pipe(
      tap((folders: Folder[]) => {
        this.upsertFolderEntities(folders);
      })
    );
  }

  isCurrentFolderExistInStore(folderId: ID): boolean {
    return !!this.getFolders().find((folder) => folder.folderId === folderId);
  }

  hasChildFolders(folderId: ID): boolean {
    const folder: Folder = this.folderQuery.getFolderById(folderId);
    const descendantFolders: Folder[] = this.folderQuery.getFolderChildren(folderId);
    return !folderId || (_isUndefined(folder?.hasChildFolder) || folder?.hasChildFolder) || !!descendantFolders.length;
  }

  getActiveFolderId(): ID {
    return this.folderQuery.getActiveFolderEntityId() || null;
  }

  setFolderActive(folderId: ID): void {
    this.setActiveFolderId(folderId || null);
  }

  permanentlyDeleteFolder(folderId: ID): Observable<WebJob> {
    return this.folderHttpService.permanentlyDeleteFolder(folderId).pipe(
      tap(() => {
        this.deleteFolderEntities(folderId);
      })
    );
  }

  resetBreadcrums(): void {
    this.setActiveFolderId(null);
    // this is to reset URL path when deleteAll performed in a child folder!
    this.location.replaceState(FilesRoutes.DeletedFiles);
  }
}
