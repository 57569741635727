<div *ngIf="art" [ngClass]="{'only-view': viewOnly}">
  <ng-container *ngIf="art.stockArtType !== stockArtType.Font">
    <form #artDescription name="artDescription" [formGroup]="artDescriptionForm" autocomplete="off">
      <!-- Art Detail Section -->
      <mat-accordion>
        <mat-expansion-panel id="artDetailsExpansionPanelContainer" class="mat-elevation-z0" (opened)="artDetailsPanel = true" (closed)="artDetailsPanel = false"
                            [expanded]="true">
          <mat-expansion-panel-header [expandedHeight]="'56px'" [collapsedHeight]="'56px'">
            <mat-panel-title id="artDetailsLabel" class="t-16-500-p">
              {{translations.art.art_details | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
            <mat-form-field appearance="fill" class="width-100">
              <mat-label id="decorationMethodLabel">{{translations.art.decoration_method | translate}}</mat-label>
              <mat-select id="decorationMethod" name="decorationMethod" formControlName="decorationMethodId"
                          [panelClass]="'outline-none'" #dmList>
                <div class="decoration-list-panel">
                  <mat-option id="decorationOptionNone" [value]="NONE">{{translations.common.none | translate}}</mat-option>
                  <ng-container *ngFor="let decorationMethod of decorationMethods; let index=index">
                    <mat-option *ngIf="!decorationMethod.deleted" id="decorationOption-{{index}}" [value]="decorationMethod.decorationMethodId"
                                class="t-14-400-p">
                      {{decorationMethod.name}}
                    </mat-option>
                  </ng-container>
                </div>
                <div id="addOrCloseDecorationMethodBtn" class="decoration-method-list-footer grid-x t-14-500-p align-middle cursor-pointer link-color"
                    (click)="dmList?.close(); openDecorationMethodModal()">
                  <mat-icon id="addOrCloseDecorationMethodIcon" class="material-icons-outlined" fontIcon="create"></mat-icon>
                  <p id="addOrCloseDecorationMethodLabel" class="m-b-0 m-l-1">{{translations.art.add_or_edit_decoration_method | translate}}</p>
                </div>
              </mat-select>
            </mat-form-field>

            <div class="grid-x grid-margin-x">
              <div class="cell large-6">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label id="widthWithUnitLabel">{{translations.common.width_with_unit | translate : {
                    x:
                    artDescriptionForm.controls['unit'].value
                  } }}</mat-label>
                  <input id="widthWithUnitInput" matInput maxlength="8" formControlName="width" [onlyNumber]="true" [readonly]="viewOnly">
                </mat-form-field>
              </div>

              <div class="cell large-6">
                <mat-form-field appearance="fill" class="width-100">
                  <mat-label id="heightWithUnitLabel">{{translations.common.height_with_unit | translate : {
                    x:
                    artDescriptionForm.controls['unit'].value
                  } }}</mat-label>
                  <input id="heightWithUnitInput" matInput maxlength="8" formControlName="height" [onlyNumber]="true" [readonly]="viewOnly">
                </mat-form-field>
              </div>
            </div>

            <mat-form-field appearance="fill" class="width-100 error-none m-b-1">
              <mat-label id="dimensionUnitLabel">{{translations.common.unit | translate}}</mat-label>
              <mat-select id="unit" name="unit" formControlName="unit">
                <mat-option id="dimensionUnitOption-mm" value="mm">{{translations.common.mm | translate}}</mat-option>
                <mat-option id="dimensionUnitOption-cm" value="cm">{{translations.common.cm | translate}}</mat-option>
                <mat-option id="dimensionUnitOption-in" value="in">{{translations.common.in | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-divider></mat-divider>

        <!-- Colors section -->
        <mat-accordion id="colorSectionContainer" *ngIf="showLoaderForColorsInUseSection || (art.details?.colorsInUse?.length || (!viewOnly || colorCodesForm.controls.length))">
          <mat-expansion-panel id="colorExpansionPanelContainer" class="mat-elevation-z0" [expanded]="true">
            <mat-expansion-panel-header [expandedHeight]="'56px'" [collapsedHeight]="'56px'">
              <mat-panel-title id="colorSelectionLabel" class="t-16-500-p">
                {{ translations.custom_stock_art.select_color.colors | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="showLoaderForColorsInUseSection">
              <div id="colorInfoLoadingContainer" class="flex-container width-100 align-center-middle">
                <mat-spinner id="colorsInfoLoader" color="primary" diameter="20"></mat-spinner>
              </div>
            </ng-container>
            <ng-container *ngIf="(art?.details?.colorsInUse?.length > 0) && !showLoaderForColorsInUseSection">
              <div id="colorInUseLabel" class="t-14-500-s m-y-2">
                {{ translations.custom_stock_art.select_color.color_in_use | translate }}
              </div>
              <div id="colorInUseItemContainer" *ngFor="let item of art.details.colorsInUse" class="flex-container m-b-1">
                <gf-color-swatch id="colorSwatchContainer" [disableTooltip]="true" [color]="item"></gf-color-swatch>
                <div id="colorSwatchInfo" class="m-l-2 overflow-hidden">
                  <div id="colorName" class="t-14-600-p text-truncate">{{ item?.name || item?.hexValue }}</div>
                  <div class="t-12-500-h flex-container">
                    <ng-container *ngIf="item?.name && !(item?.isCMYK)">
                      <div id="hexInfo" class="t-12-400-h">{{ item.hexValue }}</div>
                      <span class="m-x-1">|</span>
                    </ng-container>
                    <div *ngIf="item?.isCMYK; else rgbInfo" id="cmykInfo" class="flex-container color-info">
                      <div id="cLabel">{{translations.common.colors.c | translate}} <span id="cValue" class="t-12-400-h">{{ item?.cmyk?.cyan }}%</span></div>
                      <div id="mLabel">{{translations.common.colors.m | translate}} <span id="mValue" class="t-12-400-h">{{ item?.cmyk?.magenta }}%</span></div>
                      <div id="yLabel">{{translations.common.colors.y | translate}} <span id="yValue" class="t-12-400-h">{{ item?.cmyk?.yellow }}%</span></div>
                      <div id="kLabel">{{translations.common.colors.k | translate}} <span id="kValue" class="t-12-400-h">{{ item?.cmyk?.black }}%</span></div>
                    </div>
                    <ng-template #rgbInfo>
                      <div id="rgbInfo" class="flex-container color-info">
                        <div id="rLabel">{{translations.common.colors.r | translate}} <span id="rValue" class="t-12-400-h">{{ item?.rgb?.red }}</span></div>
                        <div id="gLabel">{{translations.common.colors.g | translate}} <span id="gValue" class="t-12-400-h">{{ item?.rgb?.green }}</span></div>
                        <div id="bLabel">{{translations.common.colors.b | translate}} <span id="bValue" class="t-12-400-h">{{ item?.rgb?.blue }}</span></div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!viewOnly || colorCodesForm.controls.length" >
              <div id="addedColorsLabel" class="t-14-500-s pb-4 m-t-2 m-b-1">{{ translations.art.added_colors_with_count | translate: { colors: colorCodesForm?.controls?.length } }}</div>
              <div formArrayName="colors">
                <div id="addedColorsInfoContainer" *ngFor="let artColor of colorCodesForm.controls; index as i;" [formGroupName]="i"
                class="flex-container m-b-1">
                <add-art-color id="addedColorInfoSwatch" [color]="artColor?.value?.color || '#ffffff'" [disabled]="viewOnly" (colorChange)="editColor($event, i)"></add-art-color>
                <div class="flex-child-auto m-l-1">
                  <mat-form-field id="addedColorNameField" appearance="fill" class="icon-input width-100 error-none added-color-name-field">
                    <input id="addedColorsNameInput" matInput type="text" formControlName="name" maxLength="132" autocomplete="off" [placeholder]="translations.art.enter_color_name | translate" [readonly]="viewOnly">
                    <mat-icon *ngIf="!viewOnly"
                      id="removeAddedColorIcon"
                      class="mat-20 hint cursor-pointer"
                      matSuffix
                      (click)="removeColor(i)">
                      remove_circle_outline
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
            </ng-container>

            <button *ngIf="!viewOnly"
              id="addColorBtn"
              mat-raised-button
              color="secondary"
              class="m-y-1 full-width large"
              (click)="addColor()">
              {{ translations.art.add_color | translate }}
            </button>
          </mat-expansion-panel>
        </mat-accordion>
      <mat-divider></mat-divider>
    </form>
  </ng-container>

  <mat-accordion>
    <mat-expansion-panel id="fileDetailsExpansionPanelContainer" class="mat-elevation-z0" (opened)="artDetailsPanel = true" (closed)="artDetailsPanel = false" [expanded]="art.stockArtType === stockArtType.Font">
      <mat-expansion-panel-header [expandedHeight]="'56px'" [collapsedHeight]="'56px'">
        <mat-panel-title id="fileDetailsLabel" class="t-16-500-p">
          {{translations.art.file_details | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="flex-container">
        <div>
          <div id="fileNameLabel" class="t-14-400-s pb-4">{{translations.common.name | translate}}</div>
          <div id="fileTypeLabel" class="t-14-400-s pb-4">{{translations.common.type | translate}}</div>
          <div id="fileSizeLabel" class="t-14-400-s pb-4">{{translations.common.size | translate}}</div>
          <div id="fileCreatedLabel" class="t-14-400-s pb-4">{{translations.common.created | translate}}</div>
          <div id="fileModifiedLabel" class="t-14-400-s pb-4">{{translations.common.modified | translate}}</div>
          <div *ngIf="art?.artExtensions[2]?.width && art?.artExtensions[2]?.height" id="fileDimensionLabel" class="t-14-400-s pb-4">{{translations.common.dimensions | translate}}</div>
        </div>
        <div class="flex-child-auto m-l-3">
          <div id="fileNameValue" class="t-14-400-p pb-4 text-truncate file-name-width">{{art?.fileName}}</div>
          <div id="fileTypeValue" class="t-14-400-p pb-4">{{(art?.stockArtType === stockArtType.Font ? art?.name : art?.fileName) | fileExtension | uppercase}}</div>
          <div id="fileSizeValue" class="t-14-400-p pb-4">{{(art?.artExtensions[2]?.memorySize || art?.fileStorageUsage) | filesize : { base: 2, standard: 'jedec' } }}</div>
          <div id="fileCreatedValue" class="t-14-400-p pb-4">{{art?.createdDate | fromNowDate}}</div>
          <div id="fileModifiedValue" class="t-14-400-p pb-4">{{art?.modifiedDate | fromNowDate}}</div>
          <div *ngIf="art?.artExtensions[2]?.width && art?.artExtensions[2]?.height" id="fileDimensionValue" class="t-14-400-p pb-4">{{art?.artExtensions[2]?.width + ' x ' + art?.artExtensions[2]?.height}}</div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-divider></mat-divider>

  <!--hide for now GF-514 -->
  <!-- <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0" (opened)="artDetailsPanel = true"
                         (closed)="artDetailsPanel = false">
      <mat-expansion-panel-header [expandedHeight]="'56px'" [collapsedHeight]="'56px'">
        <mat-panel-title class="t-16-500-p">
          Activity
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>On the way!</p>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-divider></mat-divider> -->
</div>
