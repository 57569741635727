export enum NudgePosition {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight'
}

export enum FlipPosition {
  Vertical = 'Vertical',
  Horizontal = 'Horizontal'
}
