import { Injectable } from '@angular/core';
import { addEntities, deleteEntities, setEntities, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { ArtApprovalHttpService } from '@graphics-flow/api';
import {
  ArtApproval,
  ArtApprovalItem,
  ArtApprovalPackage,
  ArtApprovalStatus,
  ArtApprovalUI,
  Collaborator, CollaboratorLink, CollaboratorRole, CreateArtApprovalForm, ID, InternalNotes,
  TimelineEvent
} from '@graphics-flow/types';
import { LoadingService } from 'shared/ui';

import { ApprovalHelper } from '../../helpers/approval.helper';
import { ArtService } from '../art/art.service';
import { CollaboratorStore } from '../collaborator/collaborator.state';
import { TeamService } from '../team/team.service';
import { TimelineService } from '../timeline/timeline.service';
import { ApprovalDetailStore } from './approval-detail/approval-detail.state';
import { ApprovalQuery } from './approval.query';
import { ApprovalStore } from './approval.state';

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {
  public createNewArtApproval = false;

  constructor(
    private readonly approvalHttpService: ArtApprovalHttpService,
    private readonly approvalStore: ApprovalStore,
    private readonly collaboratorStore: CollaboratorStore,
    private readonly artService: ArtService,
    private readonly teamService: TeamService,
    private readonly approvalQuery: ApprovalQuery,
    private readonly approvalDetailStore: ApprovalDetailStore,
    private readonly loadingService: LoadingService,
    private readonly timeLineService: TimelineService) {
  }

  addArtToApproval(artIds: ID[], approvalId: ID): Observable<ArtApprovalPackage> {
    return this.approvalHttpService.addArtToApproval(artIds, approvalId).pipe(
      tap((artApprovalPackage: ArtApprovalPackage) => {
        // Update the Approval
        this.updateApprovalEntities(artApprovalPackage.artApproval.artApprovalId, () => artApprovalPackage.artApproval);

        // Update Art - probably not necessary, but we've got it so why not?
        this.artService.addArtToStore(artApprovalPackage.art);
        this.timeLineService.updateTimelineEvent(artApprovalPackage.artApproval.timelineEvents);
      })
    );
  }

  addStockArtToApproval(artIds: ID[], approvalId: ID): Observable<ArtApprovalPackage> {
    return this.approvalHttpService.addStockArtToApproval(artIds, approvalId).pipe(
      tap((artApprovalPackage: ArtApprovalPackage) => {
        // Update the Approval
        this.updateApprovalEntities(artApprovalPackage.artApproval.artApprovalId, () => artApprovalPackage.artApproval);
        this.artService.addArtToStore(artApprovalPackage.art);
        this.timeLineService.updateTimelineEvent(artApprovalPackage.artApproval.timelineEvents);
      })
    );
  }

  addCollaboratorsToApproval(approvalId: ID, emails: string[], message: string, role: CollaboratorRole, collaboratorId: ID): Observable<Collaborator[]> {
    const obs: Observable<Collaborator>[] = emails.map(email => this.addCollaboratorToApproval(approvalId, email, message, role, null, collaboratorId));
    return forkJoin(...obs);
  }

  addCollaboratorToApproval(approvalId: ID, email: string, message: string, role: CollaboratorRole, name: string, collaboratorId?: ID): Observable<Collaborator> {
    return this.approvalHttpService.addCollaboratorToApproval(approvalId, name, email, message, role, collaboratorId ).pipe(
      tap((collaborator: Collaborator) => {
        this.collaboratorStore.update(addEntities(collaborator));
        this.updateApprovalModifiedDate(approvalId, collaborator.modifiedDate)
      })
    )
  }

  resendArtApproval(approvalId: ID): Observable<any> {
    const collaboratorId: ID = this.approvalDetailStore.getValue().collaboratorId;
    return this.approvalHttpService.resendInviteToCollaborators(approvalId, collaboratorId).pipe(
      tap((timelineEvent: TimelineEvent) => {
        this.timeLineService.updateTimelineEvent([timelineEvent]);
      })
    );
  }

  getCollaborators(approvalId: ID): Observable<Collaborator[]> {
    return this.approvalHttpService.getCollaborators(approvalId).pipe(
      tap((collaborators: Collaborator[]) => {
        this.collaboratorStore.update(upsertEntities(collaborators));
      })
    );
  }

  uploadFileToApproval(file: File, approvalId: ID): Observable<ArtApprovalPackage> {
    return this.approvalHttpService.uploadArtToApproval(file, approvalId).pipe(
      tap((reply: ArtApprovalPackage) => {
        this.artService.addArtToStore(reply.art);
        this.updateApprovalEntities(reply.artApproval.artApprovalId, () => reply.artApproval);
        this.timeLineService.updateTimelineEvent(reply.artApproval.timelineEvents);
      }),
    );
  }

  updateCollaboratorRole(collaboratorId: ID, role: CollaboratorRole): Observable<Collaborator> {
    return this.approvalHttpService.updateCollaboratorRole(collaboratorId, role).pipe(
      tap((collaborator: Collaborator) => {
        this.collaboratorStore.update(upsertEntities(collaborator));
      })
    );
  }

  getApprovalPackage(id: ID): Observable<ArtApprovalPackage> {
    return this.approvalHttpService.getPackage(id).pipe(
      tap((data: ArtApprovalPackage) => {
        this.teamService.upsertUserEntities(data.commentUsers);
        this.artService.addArtToStore(data.art);
        this.collaboratorStore.update(upsertEntities(data.commentCollaborators));
        this.upsertApprovalEntities([data.artApproval]);
      })
    );
  }

  getApprovals(): Observable<ArtApproval[]> {
    return this.approvalHttpService.get().pipe(
      tap((approvals: ArtApproval[]) => {
        this.upsertApprovalEntities(approvals);
      })
    );
  }

  createApproval(artApproval: ArtApproval): Observable<ArtApproval> {
    return this.approvalHttpService.create(artApproval);
  }

  updateApproval(artApproval: ArtApproval): Observable<ArtApproval> {
    return this.approvalHttpService.update(artApproval).pipe(
      tap((reply: ArtApproval) => {
        this.updateApprovalEntities(artApproval.artApprovalId, () => reply);
        this.timeLineService.updateTimelineEvent(reply.timelineEvents);
      })
    );
  }

  setApprovalStatus(artApproval: ArtApproval, status: ArtApprovalStatus): Observable<ArtApproval> {
    const date: Date = status === ArtApprovalStatus.ARCHIVED ? new Date() : null;
    const updatedApproval: ArtApproval = Object.assign({}, artApproval, { archivedDate: date, status });
    return this.updateApproval(updatedApproval);
  }

  setApprovalWatermark(artApproval: ArtApproval, value: boolean): Observable<ArtApproval> {
    const updatedApproval: ArtApproval = Object.assign({}, artApproval, { watermarkEnabled: value });
    return this.updateApproval(updatedApproval);
  }

  setApprovalAssignee(artApproval: ArtApproval, assigneeId: ID): Observable<ArtApproval> {
    const updatedApproval: ArtApproval = Object.assign({}, artApproval, { assigneeId });
    return this.updateApproval(updatedApproval);
  }

  setApprovalInternalNotes(artApproval: ArtApproval, notes: InternalNotes): Observable<ArtApproval> {
    const updatedApproval: ArtApproval = Object.assign({}, artApproval, { internalNotes: notes });
    return this.updateApproval(updatedApproval);
  }

  commentOnApproval(comment: string,
    approvalId: ID,
    approvalItemId: ID,
    parentCommentId?: ID): Observable<ArtApproval> {
    return this.approvalHttpService.addCommentToApproval(
      comment,
      approvalId,
      approvalItemId,
      parentCommentId).pipe(
      tap((createdApproval: ArtApproval) => {
        this.updateApprovalEntities(createdApproval.artApprovalId, createdApproval);
      })
    );
  }

  deleteCommentFromApproval(approvalId: ID, commentId: ID): Observable<ArtApproval> {
    return this.approvalHttpService.deleteCommentFromApproval(approvalId, commentId).pipe(
      tap((reply: ArtApproval) => {
        this.updateApprovalEntities(reply.artApprovalId, () => reply);
      })
    );
  }

  deleteArtApproval(artApproval: ArtApproval): Observable<ArtApproval> {
    return this.approvalHttpService.deleteArtApproval(artApproval).pipe(
      tap((reply: ArtApproval) => {
        this.deleteApprovalEntities(reply.artApprovalId);
      })
    );
  }

  deleteArtApprovalItem(approvalId: ID, approvalItemId: ID): Observable<ArtApproval> {
    const approvalDetailItemComponentLoader = 'approval_detail_item_page_loader';
    this.loadingService.showLoader(approvalDetailItemComponentLoader);
    return this.approvalHttpService.deleteArtApprovalItem(approvalId, approvalItemId).pipe(
      tap((reply: ArtApproval) => {
        this.updateApprovalEntities(approvalId, () => reply);
        this.timeLineService.updateTimelineEvent(reply.timelineEvents);
      }),
      finalize(() => {
        this.loadingService.hideLoader(approvalDetailItemComponentLoader);
      })
    );
  }

  private getNewArtApproval(artApproval: ArtApproval, artApprovalItem: ArtApprovalItem): ArtApproval {
    const DIFFERENCE = 0.5;
    ApprovalHelper.sortItems(artApproval);
    const approvalItems: ArtApprovalItem[] = artApproval.sections[0].items;
    // Update the SortOrder for the altered ArtApprovalItems
    const currentIndex = approvalItems.findIndex((item: ArtApprovalItem) => item.artApprovalItemId === artApprovalItem.artApprovalItemId);
    const oldSortOrder = approvalItems[currentIndex].sortOrder;
    const currentSortOrder = artApprovalItem.sortOrder;
    const newSortOrder = oldSortOrder > currentSortOrder ? currentSortOrder - DIFFERENCE : currentSortOrder + DIFFERENCE;
    // need to preserv comments
    const comments = approvalItems[currentIndex].comments;
    approvalItems[currentIndex] = {
      ...approvalItems[currentIndex],
      ...artApprovalItem,
      sortOrder: newSortOrder
    };
    ApprovalHelper.sortItems(artApproval);
    // Update the sortOrder of the remaining ArtApprovalItems
    approvalItems.forEach((item: ArtApprovalItem, i: number) => {
      item.sortOrder = approvalItems.length - i - 1;
    });
    approvalItems[currentIndex].comments = comments;
    return artApproval;
  }

  updateArtApprovalItemDetail(approvalId: ID, artApprovalItem: ArtApprovalItem, isWaterMarkUpdated: boolean = false, actionComment?: string): Observable<ArtApprovalPackage> {
    return this.approvalHttpService.updateArtApprovalItem(approvalId, artApprovalItem, isWaterMarkUpdated, actionComment).pipe(
      tap((artApprovalPackage: ArtApprovalPackage) => {
        const artApproval: ArtApproval = this.getNewArtApproval(artApprovalPackage.artApproval, artApprovalItem);
        this.approvalStore.update(updateEntities(approvalId, () => artApproval));
        this.timeLineService.updateTimelineEvent(artApprovalPackage.artApproval.timelineEvents);
      })
    );
  }

  updateArtApprovalItem(approvalId: ID, artApprovalItem: ArtApprovalItem, isWaterMarkUpdated: boolean = false, actionComment?: string) {
    return this.approvalHttpService.updateArtApprovalItem(approvalId, artApprovalItem, isWaterMarkUpdated, actionComment).pipe(
      tap((artApprovalPackage: ArtApprovalPackage) => {
        this.updateApprovalEntities(approvalId, () => artApprovalPackage.artApproval);
        this.artService.addArtToStore(artApprovalPackage.art);
      })
    );
  }

  refreshApprovalItemWaterMark(approvalId: ID, approvalItemId: ID): Observable<ArtApproval> {
    return this.approvalHttpService.refreshApprovalItemWaterMark(approvalId, approvalItemId).pipe(
      tap((artApproval: ArtApproval) => {
        this.updateApprovalEntities(approvalId, artApproval);
      })
    );
  }

  updateUI(ui: ArtApprovalUI) {
    this.approvalStore.updateUI(ui);
  }

  resetUI() {
    this.approvalStore.resetUI();
  }

  refreshApprovals(): Subscription {
    return this.getApprovals().subscribe();
  }

  removeCollaborator(approvalId: ID, collaboratorId: ID, currentCollaboratorId?: ID): Observable<any> {
    return this.approvalHttpService.removeCollaborator(approvalId, collaboratorId, currentCollaboratorId).pipe(
      tap((collaborator: Collaborator) => {
        this.collaboratorStore.update(deleteEntities(collaboratorId));
        this.updateApprovalModifiedDate(approvalId, collaborator.modifiedDate);
      })
    );
  }

  createShareLink(approvalId: ID): Observable<CollaboratorLink> {
    return this.approvalHttpService.createShareLink(approvalId);
  }

  updateShareableCollaboratorLinkId(approvalId: ID, sharableLinkId: ID) {
    this.updateApprovalEntities(approvalId, { shareableCollaboratorLinkId: sharableLinkId });
  }

  addApproval(artApproval: ArtApproval): void {
    this.upsertApprovalEntities(artApproval);
  }

  updateApprovalModifiedDate(approvalId: ID, date: Date) {
    const artApproval = this.approvalQuery.getApprovalById(approvalId);
    const updatedApproval: ArtApproval = Object.assign({}, artApproval, { modifiedDate: date });
    this.updateApprovalEntities(approvalId, updatedApproval);
  }

  createArtApprovalAndAddArt(artIds: ID[], approvalForm: CreateArtApprovalForm): Observable<Partial<ArtApproval>> {
    return this.approvalHttpService.createArtApprovalAndAddArt(artIds, approvalForm);
  }

  createArtApprovalAndAddStockArt(artIds: ID[], approvalForm: CreateArtApprovalForm): Observable<ArtApprovalPackage> {
    return this.approvalHttpService.createArtApprovalAndAddStockArt(artIds, approvalForm).pipe(
      tap((artApprovalPackage: ArtApprovalPackage) => {
        // Update the Approval
        this.upsertApprovalEntities(artApprovalPackage.artApproval);
        this.artService.addArtToStore(artApprovalPackage.art);
      })
    );
  }

  updateShareableLinkPermission(artApprovalId: ID, role: CollaboratorRole): Observable<boolean> {
    return this.approvalHttpService.updateShareableLinkPermission(artApprovalId, role).pipe(
      tap(() => this.addShareableLinkPermissionToApproval(artApprovalId, role))
    );
  }

  getShareableLinkPermission(approval: ArtApproval): Observable<Collaborator> {
    return this.approvalHttpService.getCollaboratorLink(approval.shareableCollaboratorLinkId).pipe(
      tap((collaborator: Collaborator) => this.addShareableLinkPermissionToApproval(approval.artApprovalId, collaborator.role))
    );
  }

  addShareableLinkPermissionToApproval(artApprovalId: ID, role: CollaboratorRole): void {
    const artApproval = this.approvalQuery.getApprovalById(artApprovalId);
    const updatedApproval: ArtApproval = Object.assign({}, artApproval, {shareableLinkPermission: role});
    this.updateApprovalEntities(artApprovalId, updatedApproval);
  }

  updateApprovalStatus(artApproval: ArtApproval, status: ArtApprovalStatus): Observable<boolean> {
    return this.approvalHttpService.updateArtApprovalStatus(artApproval.artApprovalId, status);
  }

  updateApprovalStatusInStore(artApprovalId: ID, status: ArtApprovalStatus) {
    this.updateApprovalEntities(artApprovalId, { status });
  }

  addApprovals(artApproval: ArtApproval[]) {
    this.approvalStore.update(setEntities(artApproval));
  }

  upsertApprovals(artApproval: ArtApproval[]) {
    this.upsertApprovalEntities(artApproval);
  }

  removeApprovalFromStore(approvalId: ID): void {
    this.deleteApprovalEntities(approvalId);
  }

  setApprovalEntities(value: ArtApproval []): void {
    this.approvalStore.update(setEntities(value));
  }

  updateApprovalEntities(ids: ID | ID[], partial: Partial<ArtApproval> | (() => ArtApproval)): void {
    this.approvalStore.update(updateEntities(ids, partial));
  }

  deleteApprovalEntities(ids: ID | ID[]): void {
    this.approvalStore.update(deleteEntities(ids));
  }

  upsertApprovalEntities(value: ArtApproval | ArtApproval[]): void {
    this.approvalStore.update(upsertEntities(value));
  }
}

