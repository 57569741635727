<button mat-flat-button
  id="selectFolderCdkTriggerBtn"
  class="medium width-100 select-folder-btn"
  type="button"
  (click)="toggle()"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin">
  <span class="flex-container flex-1 overflow-hidden">
    <span class="flex-container align-middle flex-1 overflow-hidden">
      <mat-icon class="m-r-2 flex-basis-auto" svgIcon="folder-icon"></mat-icon>
      <!--Once the selectedFolder implementation integrated with CSA, then selectedFolder?.name should be updated
      from CSA object as <observable> not using the current components info-->
      <span id="selectedFolderName" class="text-truncate t-14-500-p">{{(selectedFolderId | folder | async)?.name || (translations.art.my_art | translate)}}</span>
    </span>
    <mat-icon class="m-l-1 hint" fontIcon="chevron_right"></mat-icon>
  </span>
</button>

<ng-template
    cdkConnectedOverlay
    #overlay="cdkConnectedOverlay"
    [cdkConnectedOverlayHasBackdrop]="false"
    (overlayOutsideClick)="close()"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayFlexibleDimensions]="true"
    [cdkConnectedOverlayOpen]="isOpen$ | async">
  <div id="folderSelectionContainer" class="folder-selection-container flex-container flex-dir-column mat-elevation-z4">
    <div *ngIf="!!(folderMoveDialogQuery.navigationLevel$ | async); else showFolderIcon" id="parentFolderNameContainer"
      class="flex-container align-middle m-t-3 m-r-3 m-l-2"
      [ngClass]="{'m-b-3' : !!(folderMoveDialogQuery.navigationLevel$ | async)}">
      <mat-icon id="goBackTriggerIcon" class="m-r-1 mat-20 cursor-pointer" (click)="goToParent()">
        arrow_back
      </mat-icon>
      <p id="parentFolderName" class="t-16-400-p text-truncate m-b-0">
        {{ (folderMoveDialogQuery.navigationLevelFolder$ | async)?.name }}
      </p>
    </div>
    <mat-divider *ngIf="!(folderMoveDialogQuery.navigationLevel$ | async)" class="m-y-1"></mat-divider>
    <ng-template #showFolderIcon>
      <div id="parentFolderNameContainer" class="flex-container align-middle m-t-3 m-r-3 m-l-2 cursor-pointer"
        (click)="setSelectedFolder()">
        <mat-icon id="folderIcon" class="m-l-1 m-r-2" svgIcon="folder-icon"></mat-icon>
        <p id="parentFolderName" class="t-16-400-p text-truncate m-b-0">
          {{translations.art.my_art | translate}}
        </p>
      </div>
    </ng-template>
    <div *ngIf="showFolderLoader; else folderList" class="flex-container align-center-middle loader-state">
      <loading-indicator [diameter]="30"></loading-indicator>
    </div>
    <ng-template #folderList>
      <gf-mobile-view-folder-move-list-item
        class="folder-list-scroll"
        [class.expand]="showCreateFolderAction"
        [folders]="folderQuery.folders$ | async"
        (selectedFolderChange)="setSelectedFolder(selectedFolder.folderId)">
      </gf-mobile-view-folder-move-list-item>
    </ng-template>
    <div id="createFolderActionContainer" class="create-folder-action-container flex-container align-center-middle">
      <!--start of create folder trigger-->
      <button *ngIf="!showCreateFolderAction"
        id="createFolderBtn"
        mat-flat-button
        class="create-folder-btn"
        (click)="showCreateFolderAction = !showCreateFolderAction">
        <mat-icon fontIcon="add"></mat-icon>
        {{translations.folder.create_folder | translate}}
      </button>
      <!--end of create folder trigger-->
      <!--start of folder name actions-->
      <div *ngIf="showCreateFolderAction" id="createNewFolderActionContainer" class="flex-container flex-dir-column p-y-2 p-x-3 width-100">
        <mat-form-field appearance="fill" class="normal-input width-100 custom-bottom-padding">
          <input matInput
            id="folderNameInput"
            name="folderName"
            [ngModel]="newFolderName"
            (ngModelChange)="updateNewFolderName($event)">
        </mat-form-field>
        <div class="flex-container align-right">
          <button id="resetFolderInfo"
            mat-raised-button
            color="secondary"
            class="m-r-1 medium"
            (click)="resetFolderInfo()">
            {{translations.common.cancel | translate}}
          </button>
          <button mat-raised-button
            id="createNewFolderBtn"
            color="primary"
            class="medium"
            [disabled]="!newFolderName || inProgress"
            (click)="createNewFolder()">
            <span *ngIf="!inProgress">{{translations.common.create | translate}}</span>
            <mat-icon id="createNewFolderBtnLoader" *ngIf="inProgress" class="m-l-1">
              <mat-spinner color="primary" diameter="20"></mat-spinner>
            </mat-icon>
          </button>
        </div>
      </div>
      <!--end of folder name actions-->
    </div>
  </div>
</ng-template>
