import { SelectionModel } from '@angular/cdk/collections';
import { ElementRef, OnInit } from '@angular/core';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IPageInfo } from '@genexus/ngx-virtual-scroller';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Translations } from '@graphics-flow/shared/assets';
import { Art, ID, ImagePreviewType, MyArtActiveQueryParams, Order, TableFile } from '@graphics-flow/types';
import { ArtHelper, MyArtSearchQuery, MyArtSearchService, OrganizationQuery } from '@graphics-flow/util';
import { WindowService } from 'shared/util';

@UntilDestroy()
@Component({
  selector: 'gf-art-list',
  templateUrl: './art-list.component.html',
  styleUrls: ['./art-list.component.scss']
})
export class ArtListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) matSort: MatSort;
  @Input() showActions = true;
  @Input() dataSource: MatTableDataSource<TableFile>;
  @Input() useRouter = true;
  @Input() scrollingBlock: ElementRef;
  @Input() selectMultiple: boolean;
  @Input() checkFileFormat: boolean;
  @Output() renameFolder: EventEmitter<ID> = new EventEmitter<ID>();
  @Output() moveFolder: EventEmitter<ID> = new EventEmitter<ID>();
  @Output() selectFolder: EventEmitter<ID> = new EventEmitter<ID>();
  @Output() selectArts: EventEmitter<ID[]> = new EventEmitter<ID[]>();
  @Output() scrolledToBottom: EventEmitter<number> = new EventEmitter<number>();

  imagePreviewType: typeof ImagePreviewType = ImagePreviewType;
  public selectedArt$: BehaviorSubject<ID[]> = new BehaviorSubject<ID[]>([]);
  artSelectionModel: SelectionModel<ID>;
  Order = Order;

  constructor(
    public translations: Translations,
    public readonly organizationQuery: OrganizationQuery,
    public readonly myArtSearchService: MyArtSearchService,
    public readonly myArtSearchQuery: MyArtSearchQuery,
    public windowService: WindowService
  ) {
  }

  ngOnInit(): void {
    this.artSelectionModel = new SelectionModel<ID>(this.selectMultiple, []);
  }

  ngAfterViewInit() {
    this.matSort.sortChange.pipe(
      untilDestroyed(this),
      tap((sort) => {
        this.myArtSearchService.setMyArtActiveQueryParams(
          <MyArtActiveQueryParams>{
            sortBy: <string>sort.active,
            orderDesc: sort.direction === Order.DESC,
            index: 0
          }
        );
      })
    ).subscribe();
  }

  get displayedColumns(): string[] {
    if (this.showActions) {
      return ['type', 'name', 'creator', 'modifiedDate', 'size', 'favorite', 'actions'];
    } else {
      return ['type', 'name', 'creator', 'modifiedDate', 'size', 'favorite'];
    }
  }

  select(row: TableFile) {
    if (ArtHelper.isNoPreviewImage(<Art>row) || (this.checkFileFormat && ArtHelper.isCSASupportedFile(<Art>row))) {
      return;
    }
    if (row.artId) {
      this.artSelectionModel.toggle(row.artId);
      this.selectArts.emit(this.artSelectionModel.selected);
    } else if (row.folderId) {
      this.selectFolder.emit(row.folderId);
      this.resetSelection();
    }
  }

  resetSelection() {
    this.artSelectionModel.clear();
    this.selectedArt$.next([]);
  }

  virtualScrollEnd(event: IPageInfo): void {
    // This condition was added to prevent, GetArts api that trigger when the container's client height is zero or negative.
    if (event?.maxScrollPosition > 0) {
      this.scrolledToBottom.emit(event.endIndex);
    }
  }

  trackTableFiles(index: number, tableFile: TableFile) {
    return tableFile?.artId || tableFile?.folderId;
  }
}
