import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService, provideNgxWebstorage, withNgxWebstorageConfig } from 'ngx-webstorage';

import { MainModule } from '@graphics-flow/feature-main';
import { WebpackTranslateLoader } from '@graphics-flow/shared/assets';
import { AppComponent } from './app.component';

import 'hammerjs';

export const ngxWebStorageConfig = {
  prefix: 'gf'
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    MainModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    MatSnackBarModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    CookieService,
    provideNgxWebstorage(
      withNgxWebstorageConfig(ngxWebStorageConfig)
    ),
    LocalStorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
