<div class="reply-wrapper flex-container">
  <div class="flex-container flex-dir-column align-middle">
    <div class="gf-user-avatar">
      <gf-user-avatar *ngIf="reply.createdBy else isCollaborator" [userId]="reply.createdBy" [size]="imagePreviewType.SMALL" [profilePicSize]="'small'"></gf-user-avatar>
    </div>
    <ng-template #isCollaborator>
      <gf-collaborator-avatar [collaborator]="reply.collaboratorId | collaborator | async" [size]="imagePreviewType.SMALL"></gf-collaborator-avatar>
    </ng-template>
    <div class="vertical-line flex-child-auto"></div>
  </div>
  <div class="flex-container flex-dir-column flex-child-auto p-a-1 reply-info-container">
    <div class="approval-comment-header flex-container align-center-middle">
      <div class="flex-container flex-dir-column flex-child-auto reply-label-container">
        <label *ngIf="reply.createdBy else showCollab" class="t-14-600-p text-truncate"
          [title]="reply.createdBy | user | async | userDisplayName : true"
          [innerHTML]="reply.createdBy | user | async | userDisplayName : true"></label>
        <!--TODO do this in a decent way-->
        <ng-template #showCollab>
          <label class="t-14-600-p text-truncate">{{(reply.collaboratorId | collaborator | async).email}}</label>
        </ng-template>
        <label class="t-14-400-h">{{reply.createdDate | fromNowDate}}</label>
      </div>
      <ng-container *ngIf="reply | canDeleteComment | async">
        <mat-icon class="cursor-pointer" [matMenuTriggerFor]="addActions" fontIcon="more_vert"></mat-icon>
        <mat-menu #addActions="matMenu">
          <button class="danger-color" mat-menu-item (click)="deleteComment.emit(reply.commentId)">{{translations.common.delete | translate}}</button>
        </mat-menu>
      </ng-container>
    </div>
    <div class="t-14-400-p text-wrap">
      {{reply.comment}}
    </div>
  </div>
</div>
