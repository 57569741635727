import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { ImagePreviewType, Organization } from '@graphics-flow/types';
import { DEFAULT_COLOR_DISCLAIMER, OrganizationQuery } from '@graphics-flow/util';
import { WindowService } from 'shared/util';

interface ApprovalDialogData {
  organization: Organization, approvalName: string
}

@Component({
  selector: 'gf-final-approval-dialog',
  templateUrl: './final-approval-dialog.component.html',
  styleUrls: ['./final-approval-dialog.component.scss']
})
export class FinalApprovalDialogComponent {

  defaultTermsAndConditions: string = DEFAULT_COLOR_DISCLAIMER;
  approvalDialogData: ApprovalDialogData;
  ImagePreviewType: typeof ImagePreviewType = ImagePreviewType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ApprovalDialogData,
    public dialogRef: MatDialogRef<FinalApprovalDialogComponent>,
    public translations: Translations,
    public organizationQuery: OrganizationQuery,
    public readonly windowsService: WindowService
  ) {
    this.approvalDialogData = data;
  }


  approve() {
    this.dialogRef.close(true);
  }
}
