import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { isUndefined as _isUndefined } from 'lodash-es';
import { ApiResponse, Art, ArtType, MyArtActiveQueryParams, Pagination } from '@graphics-flow/types';
import { MY_ART_BASE_LIMIT } from '../../constants/infinity-scroller.constants';

export interface FeaturedDesignState {
  activeQueryParams: MyArtActiveQueryParams;
  showOverAllLoader: boolean;
  showInfiniteScrollLoader: boolean;
  pagination: Pagination;
  arts: Art[];
}

export const initialFeaturedDesign: FeaturedDesignState = {
  activeQueryParams: <MyArtActiveQueryParams> {
    globalSearchText: '',
    index: 0,
    limit: MY_ART_BASE_LIMIT,
    sortBy: 'name',
    orderDesc: false,
    artType: ArtType.ADDED_TO_ART_PORTAL,
    isGlobalFilter: true
  },
  showOverAllLoader: false,
  showInfiniteScrollLoader: false,
  arts: [],
  pagination: {
    index: 0,
    includedResults: 0,
    totalResults: 0
  }
};

const { state, config } = createState(
  withProps<FeaturedDesignState>(initialFeaturedDesign),
)

@Injectable({ providedIn: 'root' })
export class FeaturedDesignStore extends Store {
  constructor() {
    super({ name: 'featured-design', state, config });
  }

  setActiveFilter(filters: MyArtActiveQueryParams) {
    this.update((state) => {
      const activeQueryParams = {
        ...state.activeQueryParams,
        globalSearchText: !_isUndefined(filters.globalSearchText) ? filters?.globalSearchText : state?.activeQueryParams.globalSearchText,
        index: !_isUndefined(filters?.index) ? filters?.index : state?.activeQueryParams.index,
        artType: filters?.artType ? filters?.artType : state?.activeQueryParams.artType,
        sortBy: filters?.sortBy ? filters?.sortBy : state?.activeQueryParams.sortBy,
        orderDesc: !_isUndefined(filters?.orderDesc) ? filters?.orderDesc : state?.activeQueryParams.orderDesc
      };
      return {
        ...state,
        activeQueryParams,
        showOverAllLoader: activeQueryParams.index === 0,
        showInfiniteScrollLoader: activeQueryParams.index !== 0
      }
    });
  }

  addAppendState(res: ApiResponse<Art[]>) {
    this.update((state) => {
      const arts = res.pagination.index === 0 ? res.data : [...state.arts, ...res.data];
      return {
        ...state,
        arts,
        pagination: res.pagination,
        showOverAllLoader: false,
        showInfiniteScrollLoader: false
      };
    })
  }
}
