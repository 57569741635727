import { Directive, ElementRef, HostListener, Renderer2, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const TRIM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TrimDirective),
  multi: true
};

@Directive({
  selector: '[trim]',
  providers: [TRIM_VALUE_ACCESSOR]
})
export class TrimDirective implements ControlValueAccessor {
  private onChange: (value: string) => void;
  private onTouched: () => void;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  writeValue(value: string): void {
    const normalizedValue: string = value ? value.trim() : '';
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', normalizedValue);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = (value: string) => {
      fn(value ? value.trim() : '');
    };
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.onChange(value.trim());
    this.onTouched(); // Notify that the control has been touched
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    this.onChange(value.trim());
    this.onTouched();  // Notify that the control has been touched
  }
}
