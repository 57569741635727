import { Pipe, PipeTransform } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { CollaboratorRole } from '@graphics-flow/types';

@Pipe({
  name: 'getCollaboratorPermissionOrDescription'
})
export class GetCollaboratorPermissionOrDescriptionPipe implements PipeTransform {
  constructor(private translations: Translations) { }

  transform(role: CollaboratorRole, isDescription: boolean = false): string {
    switch (role) {
      case CollaboratorRole.READ_ONLY:
        return isDescription ? this.translations.approval.anyone_with_link_can_view : this.translations.approval.can_view;
      case CollaboratorRole.VIEW:
        return isDescription ? this.translations.approval.anyone_with_link_can_comment : this.translations.approval.can_comment;
      case CollaboratorRole.EDIT:
        return isDescription ? this.translations.approval.anyone_with_link_can_approve : this.translations.approval.can_comment_and_approve;
      default:
        return isDescription ? this.translations.approval.anyone_with_link_can_view : this.translations.approval.can_view;
    }
  }
}
